<template>
  <div>
    <NuxtLayout>
      {{ error.statusCode }} - {{ error.message }}
      <button @click="handleError">
        Ga terug
      </button>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import {useRouter} from '#app';

definePageMeta({
  layout: 'default',
});

interface Props {
  error: any,
}

defineProps<Props>();

const handleError = () => useRouter().go(-1);
</script>
